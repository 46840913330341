export default {
  zj: [ // 专家讲师介绍
    {
      imgUrl: '2_1.png',
      name: '陈军燕老师',
      content: `
      Ms. Junyan Chen <br/>
      International Image Etiquette (N-1) Course Creator <br/>
      Certified Senior Image Manager, Ministry of Culture <br/>
      Co-Creation and Re-Education International Etiquette Project Exchange Sponsor, <br/>
      讲师：陈军燕（EET 授课） <br/>
      国际形象礼仪专业委员会专家 <br/>
      IBIE国际商务形象礼仪标准与测评导师 <br/>
      贵州民族大学客座副教授 <br/>
      教育部职业院校教师 <br/>
      国际职业经理人协会高级注册培训师 <br/>
      高级礼仪培训师 <br/>
      中国礼宾礼仪文化专业委员会理事 <br/>
      北大EMBA班、猎聘学院等机构特约讲师 <br/>
      国际航空协会北亚中国区首席乘务教官 <br/>
      中国民航总局客舱乘务监察员资质`
    }, 
    {
      imgUrl: '2_2.png',
      name: '董畅老师',
      content: `IBIE 国际形象礼仪组织协会形象讲师 <br/>
      AICI 国际形象顾问协会北京分会副主席 <br/>
      中韩美业大赛评委 <br/>
      辽美协化妆专业委员会秘书长 <br/>
      国际认证形象礼仪讲师 <br/>
      国际认证形象顾问 <br/>
      国际认证芳疗项目管理师 <br/>
      国家美容教师资质 <br/>
      国家美容高级技师资质 <br/>
      国家形象设计师高级资质`
    },
    {
      imgUrl: '2_3.png',
      name: '乔立君老师',
      content: `Mr. Vincent Qiao   <br/>
      Deputy General Manager, Neusoft Group Division; <br/>
      IBIE International Commissioner 2019; <br/>
      20+ years IT Solution & Product Management experiences; <br/>
      Experienced in Global Business Cooperation and Management; <br/>
      Managed business with Microsoft, Honeywell, Amazon, Intel, EMC, Nokia, and etc. <br/>
      Domestic customers cover CMCC, PICC, Wanda, UNIONPAY, Xinhua news agency, Ministry of Labor and Social Security. <br/>
      讲师：乔立君老师（EET 授课） <br/>
      东软集团股份有限公司 事业部副总经理； <br/>
      IBIE协会2019年度国际特派委员； <br/>
      20年以上IT解决方案和产品及团队管理经验； <br/>
      长期与微软、霍尼韦尔、亚马逊、英特尔、EMC、诺基亚等系列世界顶级公司进行业务合作。国内客户覆盖中国移动、中国人保、万达集团、中国银联、新华社、劳动和社会保障部等等。`
    },
    {
      imgUrl: '2_4.png',
      name: '王云老师',
      content: `知名时尚形象管理专家、形象顾问导师 <br/>
      最受企业欢迎培训师誉畅销书作家服装设计师 <br/>
      形象搭配魔方(ICMC)系统创始人 <br/>
      IBIE国际形象礼仪组织协会理事 <br/>
      中国服装设计师协会专业委员会委员 <br/>
      历任国际形象顾问协会全球理事、北京分会主席 <br/>
      国际认证形象专家 AICI CIP <br/>
      华盛顿全球个人形象顾问大奖唯一华人获得者 <br/>
      美国 BODY BEAUTIFUL学院风格大师认证顾问 <br/>
      美国约形象资讯中心认证顾问 <br/>
      法国形象教练学院认证顾问 <br/>
      加拿大形象学院沟通管理认证顾问 <br/>
      清华大学培训中心、北京大学精英班特邀讲师 <br/>
      北京电视台、中国教育台特邀形象顾问讲师 <br/>
      出版著作10余本，行业中出版著作最多的形象顾问导师之一`
    },
    {
      imgUrl: '2_5.png',
      name: '肖倪老师',
      content: `Ms. Xiao Ni <br/>
      Master of Arts in Art, Beijing Normal University <br/>
      Teacher of "Shape and Social Etiquette" at Beijing University of Posts and Telecommunications <br/>
      Teaching Director, Courses as "Appreciation of Dance Art" and "Introduction to Art" <br/>
      Participation for the State Patent Office Cofco Group and other government departments <br/>
      Enterprises and institutions as social government etiquette and Knowledge training and lectures on business reception <br/>
      肖妮老师 <br/>
      北京师范大学艺术学硕士学位 <br/>
      北京邮电大学《形体与社交礼仪》任课教师 <br/>
      曾担任《舞蹈艺术鉴赏》《艺术概论》等课程的教学工作 <br/>
      多次为国家专利局、中粮集团等政府部门 <br/>
      企事业单位担任社交、政务礼仪和 <br/>
      商务接待等知识培训和讲座`
    },
    {
      imgUrl: '2_6.png',
      name: '赵琳老师',
      content: `Dr. Lin Zhao   <br/>
      Associate Professor, Academy of broadcasting Host Art, China Media University <br/>
      The practice of oral communication in the majors of news and communication <br/>
      Master's Tutor, Language Communication 
      <br/>Former CCTV reporter, Chinese and English interview with international dignitaries Dedicated to cross-cultural language communication and public image research for more than ten years
                                  
      <br/>赵博士                              
      <br/>中国传媒大学播音主持艺术学院 副教授 <br/>
      新闻与传播专业口语传播实务 <br/>
      语言传播专业 硕士研究生导师 <br/>
      曾任央视记者，中英文专访国际政要 <br/>
      潜心致力于跨文化语言传播与公众形象研究十余年`
    },
    {
      imgUrl: '2_7.png',
      name: '周加李老师',
      content: `Ph.D., Zhou Jiali
      <br/>  S.J.D，Doctor of Judicial Science
      <br/> Full-time teacher of the Department of Diplomacy and Foreign Affairs Management, School of Foreign Affairs, School of Foreign Affairs
      <br/>  Visiting Professor, Institute of International Business Officials, Central University of Finance and Economics/Ministry of Commerce
      <br/> Visiting Scholar at Bond University of Australia and Oxford University, UK
     <br/> International Image Consultant Association AICI Certified Image Expert
     <br/> Vice President, Beijing Branch, International Image Consultant Association
     <br/> Publishing monograph "External communication and personal image shaping" "Foreign etiquette" "Clothing can be simple accessories can not be sloppy"
     <br/> Editor-in-Chief "From Theory to Practice - Diplomatic Protocol Etiquette Research"
     <br/> Editor-in-Chief of "New Civil Service Foreign Affairs Etiquette"
     <br/> Participated in the writing of the Foreign Affairs Personnel Knowledge Handbook
     <br/> Published more than forty papers and articles
      
     <br/> 法学博士
     <br/> 外交学院外交学与外事管理系公共外交教研室专职教师
     <br/> 中央财经大学/商务部国际商务官员研修学院客座教授
     <br/> 澳大利亚邦德大学与英国牛津大学访问学者
     <br/> 国际形象顾问协会AICI认证形象专家
     <br/> 国际形象顾问协会北京分会副主席
     <br/> 出版专著《对外交流与个人形象塑造》《涉外礼仪》《服装可以简单 配饰不能马虎》
     <br/> 主编《从理论到实践—外交礼宾礼仪研究》
     <br/> 主编《新编公务员外事礼仪》
     <br/> 参与撰写《公职人员外事知识手册》
     <br/> 发表各种论文与文章四十余篇`
    },
    {
      imgUrl: '2_8.png',
      name: 'Lanie Denslow老师',
      content: `Lanie Denslow
     <br/> Founder & Principal
     <br/> World Wise Intercultural Training & Resources
     <br/> Lanie is a graduate of the Protocol School of Washington and holds a BS from Antioch University and both a Masters of International Business and an MBA from Pepperdine University.
     <br/> Lanie’s work in the area of international trade and diplomacy has been recognized by multiple organizations. She received the Spirit of Diplomacy award from Protocol and Diplomacy International - The Protocol Officers Association and the Special Recognition Award for outstanding contributions to the world trade community from the Los Angeles Area Chamber of Commerce.
      
     <br/> 拉尼·登斯洛
     <br/> 创始人兼校长
     <br/> 世界智者跨文化培训与资源
     <br/> Lanie 毕业于华盛顿礼宾学院,拥有安提奥克大学学士学位、国际商业硕士和佩珀丁大学工商管理硕士学位。
     <br/> 拉尼在国际贸易和外交领域的工作得到了多个组织的承认。她获得了《议定书与外交国际——礼宾官员协会》颁发的"外交精神奖",以及洛杉矶地区商会颁发的对世界贸易界的杰出贡献特别表彰奖。`
    },
    {
      imgUrl: '2_9.png',
      name: 'Nathan Jackson老师',
      content: `Nathan Jackson
      <br/> Beijing Center Education Manager (2013 - Present)
      <br/> Created English Environment standards that were implemented nationwide.
      <br/> International Diploma for Language Teaching Management (IDLTM/DELTM) 
      <br/> Graduate of the University of Western Ontario (5 year, double major)  
      <br/> in East Asian Studies: China
      <br/> in Spanish Language and Linguistics
      <br/> Chinese Language Proficiency Exam (advanced level, HSK 5)   
      
      <br/> 内森·杰克逊老师
      <br/> 北京教育 中心教育经理（2013年至今）
      <br/> 创建了在全国范围内实施的英语环境标准。
      <br/> 高级教师/初级教育经理
      <br/> 国际语言教学管理文凭（IDLTM/DelTM）
      <br/> 西安大略大学毕业（5年，双专业）
      <br/> 东亚研究学士学位：中国 
      <br/> 西班牙语和语言学学士 
      <br/> 汉语水平考试（高级水平，HSK 5）`
    },
  ]
}