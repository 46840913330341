<template>
  <div class="Box">
    <div class="leftBox">
      <textTitle :text="title" />
      <el-collapse  class="famousTeacher" v-model="activeNames" >
        <el-collapse-item v-for="(item, index) in data" :key="index" :title="item.name" :name="index + 1">
          <img class="img" :src="item.imgUrl" alt="" align="left">
          <div class="content" v-html="item.content"></div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <sidebar />
  </div>
</template>
<script>
import textTitle from "@/components/headTail/textTitle";
import sidebar from "@/components/sidebar/sidebar";
import teacherJson from './teacher.js'
export default {
  components: {
    textTitle,
    sidebar,
  },
  data() {
      return {
        activeNames: [1],
        data: [],
        tag: this.$route.query.tag,
        title: this.$route.query.tag == 'zj' ? '专家讲师介绍' : '创始人介绍'
      };
  },
  created() {
      this.data = teacherJson[this.$route.query.tag]
      this.data.forEach((element, idx) => {
              this.data[idx].imgUrl = require('../../assets/img/famousTeacher/'  + element.imgUrl)
      });

  }
};
</script>
<style lang="scss" scoped>
.famousTeacher{
  margin-right: 30px;
  .img{
    width: 150px;
  }
  .content{
    margin-left: 180px;
  }
}

</style>